import styled, { keyframes } from 'styled-components';
import React, { Component } from 'react';
import { ThemeContext } from '../themeContext'
import { Inview } from '../Inview'
import { gsap, TweenMax, Expo, Back, Power4, TimelineMax, Power2 } from "gsap";


class TopFv extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
  }

  componentWillUnmount() {

  }
  render() {
    const zeroPadding = (num, len) => {
      return (Array(len).join("0") + num).slice(-len);
    }
    const dateDisplay = (date) => {
      if (date) {
        var date = new Date(date)
        return `${date.getFullYear()}.${zeroPadding(
          date.getMonth() + 1,
          2
        )}.${zeroPadding(date.getDate(), 2)}`
      } else {
        return;
      }
    }
    let isDay = ''
    if (this.context.location.pathname) {
      isDay = this.context.location.pathname.indexOf('/person-day') !== -1
    }
    return (
      <PersonTop className={(isDay ? 'day' : "night")}>
        <PersonHead className={(isDay ? 'day' : "night")}>
          <PersonHeadInner>
          YAITAERS  <span className="sm">#{this.props.person.number}</span>
          </PersonHeadInner>
        </PersonHead>
        <PersonName className={(isDay ? 'day' : "night")}>
          <PersonNameEn className={(isDay ? 'day' : "night")}>
            {this.props.person.name_en}
          </PersonNameEn>
          <PersonNameJp>
            {this.props.person.name_jp}さん
          </PersonNameJp>
        </PersonName>
        <PersonPhoto className={(isDay ? 'day' : "night")}>
          Photographer {this.props.person.photographer}
        </PersonPhoto>
        <PersonDate className={(isDay ? 'day' : "night")}>
          {dateDisplay(this.props.person.createdAt)}
        </PersonDate>
      </PersonTop>
    )
  }
}


const PersonTop = styled.div`
padding-top: 80px;
padding-bottom: 80px;
&.day{
  color: #666666;
}
&.night{
  color: #ccc;
}
`;

const PersonHeadInner = styled.span`
position: relative;
`;
const PersonHead = styled.h2`
font-size: 40px;
font-family: "Poiret One", cursive;
font-weight: normal;
letter-spacing: 10px;
@media (max-width: 768px) {
  font-size: 11vw;
  letter-spacing: 8px;
  padding-right: 10vw;
  .sm{
    font-size: 60%;
    position: absolute;
    top: 50%;
    right: -14vw;
    transform: translateY(-50%);
  }
  } 
&.day{
  color: #666666;
}
&.night{
  color: #ccc;
}
`;
const PersonName = styled.h1`
font-size: 40px;
font-weight: normal;
margin-top: 30px;
@media (max-width: 768px) {
  margin-top: 0px;
}
&.day{
  color: #666666;
}
&.night{
  color: #ccc;
}
`;
const PersonNameEn = styled.div`
font-size: 16px;
font-family: "Poiret One", cursive;
letter-spacing: 5px;
@media (max-width: 768px) {
}

&.day{
  color: #000;
}
&.night{
  color: #fff;
}


`;
const PersonNameJp = styled.div`
font-weight: 300;
font-size: 24px;
letter-spacing: 5px;
margin-top: 8px;
@media (max-width: 768px) {
 font-size: 4.4vw; 
 margin-top: 0vw;
 letter-spacing: 2px;
}
`;
const PersonPhoto = styled.div`
font-weight: 100;
font-family: "Poiret One", cursive;
letter-spacing: 3px;
margin-top: 60px;
font-size: 12px;
@media (max-width: 768px) {

  font-size: 11px;
  margin-top: 65px;
}

&.day{
  color: #000;
}
&.night{
  color: #fff;
}
`;
const PersonDate = styled.div`
font-weight: 100;
font-family: "Poiret One", cursive;
letter-spacing: 3px;
margin-top: 40px;
font-size: 12px;
@media (max-width: 768px) {
  margin-top: 10px;
  font-size: 11px;
}

&.day{
  color: #000;
}
&.night{
  color: #fff;
}
`;



TopFv.contextType = ThemeContext;

export default TopFv;