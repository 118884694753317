import styled, { keyframes } from 'styled-components';
import React, { Component } from 'react';
import { ThemeContext } from '../themeContext'
import { Inview } from '../Inview'
import { gsap, TweenMax, Expo, Back, Power4, TimelineMax, Power2 } from "gsap";


class TopFv extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
  }

  componentWillUnmount() {

  }
  render() {
    const zeroPadding = (num, len) => {
      return (Array(len).join("0") + num).slice(-len);
    }
    const dateDisplay = (date) => {
      if (date) {
        var date = new Date(date)
        return `${date.getFullYear()}.${zeroPadding(
          date.getMonth() + 1,
          2
        )}.${zeroPadding(date.getDate(), 2)}`
      } else {
        return;
      }
    }
    let isDay = ''
    if (this.context.location.pathname) {
      isDay = this.context.location.pathname.indexOf('/yaitime-day') !== -1
    }
    return (
      <YaitimeDesc className={(isDay ? 'day' : "night")}>
        <h1 className="yaitime-title">
          {this.props.yaitime.title}
        </h1>
        <div className={(isDay ? 'day' : "night") + " yaitime-date"}>
          {dateDisplay(this.props.yaitime.createdAt)}
        </div>
        {
          this.props.yaitime.thum
          &&
          <div className="yaitime-thum">
            <img src={this.props.yaitime.thum.url} />
          </div>
        }

        <div className="yaitime-main" dangerouslySetInnerHTML={{ __html: this.props.yaitime.content }}>

        </div>


      </YaitimeDesc>
    )
  }
}


const YaitimeDesc = styled.div`
padding-top: 140px;
padding-bottom: 80px;
@media (max-width: 768px) {
  padding-top: 100px;
  }
&.day{
  color: #666666;
}
&.night{
  color: #cccccc;
}

.yaitime{
  &-content{

  }
  &-title{
    font-weight: 300;
    text-align: center;
    font-size: 26px;

    letter-spacing: 4px;
    line-height: 1.75;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 768px) {
      font-size: 20px;
  }
  }
  &-date{
    font-family: "Poiret One", cursive;
font-weight: normal;
margin-top: 40px;
    text-align: center;
    font-size: 16px;
    letter-spacing: 4px;
    @media (max-width: 768px) {
      font-size: 14px;
  }

    &.day{
      color: #000;
    
    }
    &.night{
      color: #fff;

    }
  }
  &-thum{
    width: 64%;
    margin-top: 80px;
    @media (max-width: 768px) {
      width: 100%;
      margin-top: 60px;
  }
    img{
      width: 100%;
    }
  }

  &-main{
    margin-top: 40px;
    text-align: left;
    line-height: 2.5;
    letter-spacing: 2px;

    img{
      width: 64%;
      display: block;
      @media (max-width: 768px) {
      width: 100%;
  }
    }
  }

}

`;



TopFv.contextType = ThemeContext;

export default TopFv;