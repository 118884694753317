import React, { Component } from 'react'

export const initialContext = {
  isDay: false,
  toggleDayState: () => {},
  setRouteProps: () => {},
  setLocation: ()=>{}
};

export const ThemeContext = React.createContext(
  initialContext // デフォルトの値
);