import styled from 'styled-components';
import React, { Component } from 'react';
import { ThemeContext, themes } from './themeContext'
import { easeOutQuint } from '../plugins/easing'
class AppFooter extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const { match } = this.props;
  }
  render() {
    const isDay = this.context.location.pathname === '/day' || this.context.location.pathname === '/day/' || this.context.location.pathname?.indexOf('/person-day') !== -1 || this.context.location.pathname?.indexOf('/yaitime-day') !== -1

    const snsIconsURL = [
      {
        icon: '/img/icon_in.svg',
        url: 'https://www.instagram.com/takibi.yaita/'
      },
      /* {
        icon: '/img/icon_tw.svg',
        url: 'https://twitter.com/TAKIBI01175690'
      }, */
      {
        icon: '/img/icon_fb.svg',
        url: 'https://www.facebook.com/takibiz.co'
      },
    ]

    return (
      <Footer>
        <AppFooterInner>
          <FooterInfo>
            <FooterDesc>
              <a href="https://goo.gl/maps/2USaGR6Er4LtB5pG8" target="_blank">
                <span className="en">〒329-2162</span>矢板市末広町<span className="en">19-4</span>　ココマチ1階</a>
              <br />
              <DateWrap>
              <DateHead>
              OPEN：
                </DateHead>
                <DateDesc>
                火・水・木・金 ９：００－２０：００
                <br/>土・日・月 ９：００－１７：００
                <br/>祝日、年末年始休館
                </DateDesc>
                </DateWrap>
              <span className="en"><a href="tel:0287-47-7017">0287-47-7017</a></span>
              <br /><span className="en"><a href="mailto:takibi.yaita@gmail.com">takibi.yaita@gmail.com</a></span>
            </FooterDesc>
          </FooterInfo>
          <SnsList>
            {snsIconsURL.map((data) => {
              return <SnsItem key={data.icon}>
                <a href={data.url} target="_blank">
                  <img src={data.icon} />
                </a>
              </SnsItem>;
            })}
          </SnsList>
        </AppFooterInner>
        <Copyright>
          Copyright © 2020 TAKIBI
            </Copyright>
      </Footer>
    )
  }
}

const DateWrap = styled.div`
display: grid;
grid-template-columns: auto 1fr;
gap: 10px;
`;
const DateHead = styled.div`

`;
const DateDesc = styled.div`

`;

const Footer = styled.footer`
width: 100%;
padding-top: 90px;
padding-bottom: 45px;
background-color: #0d121b;
color: #cccccc;
text-align: left;
@media (max-width: 768px) {
  padding-bottom: 35px;
  padding-top: 21vw;
}
`;
const AppFooterInner = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1046px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  @media (max-width: 768px) {
    display: block;
    width: 80%;
}
`;
const SnsList = styled.ul`
display: flex;
padding-top: 10px;
@media (max-width: 768px) {
  margin-top: 20vw;
}
`;
const SnsItem = styled.li`
width: 36px;
&:not(:first-child){
  margin-left: 20px;
}
img{
  transition: transform 1s ${easeOutQuint};
  &:hover{
      transform: scale(1.1);
  }
}
`;

const FooterInfo = styled.div`
font-size: 16px;
font-weight: 100;
@media (max-width: 768px) {
  font-size: 4vw;
}
`;
const FooterDesc = styled.div`
letter-spacing: 4px;
line-height: 2.5;
font-weight: 100;
@media (max-width: 768px) {
  letter-spacing: 2px;
  line-height: 2.4;
}
.en{
  font-family: "Quicksand", sans-serif;
font-weight: 300;
}
`;
const Copyright = styled.div`
margin-top: 88px;
font-family: "Quicksand", sans-serif;
font-weight: 300;
letter-spacing: 4px;
 
  max-width: 1046px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  @media (max-width: 768px) {
    display: block;
    width: 80%;
    letter-spacing: 1px;
    margin-top: 21vw;
}
`;




AppFooter.contextType = ThemeContext;

export default AppFooter;