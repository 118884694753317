import styled, { keyframes } from 'styled-components';
import React, { Component } from 'react';
import { ThemeContext } from '../themeContext'
import { Inview } from '../Inview'
import { gsap, TweenMax, Expo, Back, Power4, TimelineMax, Power2 } from "gsap";


class PersonList extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.gotoPerson = (slug) => {
      let isDay = ''
      if (this.context.location.pathname) {
        isDay = this.context.location.pathname.indexOf('/person-day') !== -1
      }
      if (isDay) {
        this.context.route.history.push(`/person-day/${slug}`)
      } else {
        this.context.route.history.push(`/person-night/${slug}`)
      }
    }
  }

  componentWillUnmount() {

  }
  render() {
    const zeroPadding = (num, len) => {
      return (Array(len).join("0") + num).slice(-len);
    }
    const dateDisplay = (date) => {
      if (date) {
        var date = new Date(date)
        return `${date.getFullYear()}.${zeroPadding(
          date.getMonth() + 1,
          2
        )}.${zeroPadding(date.getDate(), 2)}`
      } else {
        return;
      }
    }

    let isDay = ''
    if (this.context.location.pathname) {
      isDay = this.context.location.pathname.indexOf('/person-day') !== -1
    }
    const PersonListData = this.props.personData.map(data => {
      let pic = isDay ? data.pic_day : data.pic_night;
      return (
        <PersonItem key={data.id} onClick={() => this.gotoPerson(data.id)}>
          <div className="person-pic">
            {
              pic && <img src={pic.url} />
            }
          </div>
          <div className="person-num">
            YAITAERS  <span className="sm">#{data.number}</span>
          </div>
          <div className="person-info">
            <div className="person-copy">
              {isDay ? data.copy:data.copy_night}
            </div>
            <h3 className="person-name">
              {data.name_jp}さん
            </h3>
            <div className={'person-date ' + (isDay ? 'day' : "night")}>
              {dateDisplay(data.createdAt)}
            </div>
          </div>
        </PersonItem>
      )
    })
    return (
      <PersonListTop className={(isDay ? 'day' : "night")}>
        <PersonListWrap>
          {PersonListData}
        </PersonListWrap>
      </PersonListTop>
    )
  }
}


const PersonListTop = styled.div`
background-color: #f1f1f1;
padding: 80px 0;
@media (max-width: 768px) {
  padding: 60px 0;
  }
&.day{ 
  color: #666666;
  background-color: #f1f1f1;
}
&.night{
  color: #ccc;
  background-color: #3d3d3d;
}
`;
const PersonListWrap = styled.ul`
max-width: 1050px;
margin-left: auto;
margin-right: auto;
display: flex;
flex-wrap: wrap;
width: 90%;
@media (max-width: 768px) {
  width: 85%;
  }
`;
const PersonItem = styled.li`
width: 29%;
text-align: left;
cursor: pointer;


&:hover{
  .person{
    &-pic{
      img{
        opacity: .7;
      }
    }
  }  
}

@media (max-width: 768px) {
  width: 100%;
  }
&:not(:nth-child(3n+1)){
  margin-left: 6%;
  @media (max-width: 768px) {
    margin-left: 0;
  }
}
&:nth-child(n+4){
  margin-top: 6%;
  @media (max-width: 768px) {
    margin-top: 0;
  }
}
&:nth-child(n+2){
  @media (max-width: 768px) {
    margin-top: 10%;
  }
}
.person{
  &-num{
    font-size: 28px;
font-family: "Poiret One", cursive;
font-weight: normal;
letter-spacing: 7px; 
margin-top: 16px;
line-height: 1;
  }
  &-pic{
    position: relative;
    &:before{
      content: '';
      padding-top: 100%;
      display: block;
      background-color: #aaa;
    }
    img{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: opacity 1s cubic-bezier(0.23, 1, 0.32, 1);

    }
  }
  &-info{
    margin-top: 8px;
    letter-spacing: 4px;
    padding-right: 20px;
    position: relative;
  }
  &-name{
    font-size: 16px;
    font-weight: 300;
  }
  &-copy{

  }
  &-date{
    font-size: 10px;
    position: absolute;
    right: 0;
    bottom: 0;
    font-family: "Poiret One", cursive;
    letter-spacing: 2px;
    &.day{
      color: #000;
    }
    &.night{
      color: #fff;
    }
  }
}
`;


PersonList.contextType = ThemeContext;

export default PersonList;