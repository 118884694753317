import styled, { keyframes } from 'styled-components';
import React, { Component } from 'react';
import { ThemeContext } from '../themeContext'
import { Inview } from '../Inview'
import { gsap, TweenMax, Expo, Back, Power4, TimelineMax, Power2 } from "gsap";


class TopFv extends Component {
  constructor(props) {
    super(props);

    this.scrollInview = (e) => {
      const el = e.target
      TweenMax.staggerTo(
        el.querySelectorAll(":scope .w"),
        1.2,
        {
          y: "0px",
          force3D: true,
          ease: Power4.easeOut,
          delay: 0.3
        },
        0.03
      )
      TweenMax.to(
        '.top-fv-illust',
        {
          ease: Power4.easeOut,
          opacity: 1,
          duration: 0.2
        }
      )
    }
  }
  state = { width: 0, height: 0 };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions()

    this.onClick = () => {
      const isDay = this.context.location.pathname === '/day' || this.context.location.pathname === '/day/' || this.context.location.pathname?.indexOf('/person-day') !== -1 || this.context.location.pathname?.indexOf('/yaitime-day') !== -1
      if (isDay) {
        this.context.route.history.push('/night')
      } else {
        this.context.route.history.push('/day')
      }
    }
  }
  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: document.documentElement.clientHeight });
  };
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  render() {
    const isDay = this.context.location.pathname === '/day' || this.context.location.pathname === '/day/' || this.context.location.pathname?.indexOf('/person-day') !== -1 || this.context.location.pathname?.indexOf('/yaitime-day') !== -1
    const illustRate = window.innerWidth > 768 ? 0.63 : 2.152; // height / width
    const ww = this.state.width
    const wh = this.state.height
    const illustHeight = ww * illustRate
    let scrollTop = ''
    if (illustHeight < wh) {
      scrollTop = '88%'
    } else {
      scrollTop = `${wh * 0.88}px`
    }
    const TopScroll = styled.div`
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 2;
    top: ${scrollTop};
`;
    return (
      <TopFvContent id="top_fv">
        {
          this.props.pic ? 
          (<><TopFvIllust className="top-fv-illust pc_only" src={this.props.pic.url} /><TopFvIllust className="top-fv-illust sp_only" src={this.props.pic.url} /></>)
          : ''
        }
        <TopScroll>
          <TopScrollTxt className={isDay ? 'day' : "night"} >
            <Inview onInView={this.scrollInview}>
              {'SCROLL'.split('').map((w, i) => {
                if (w !== ' ') {
                  return <span key={i} className={`w w-${i}`}>{w}</span>;
                } else {
                  return <span key={i} className={`w w-${i}`}>&nbsp;</span>;
                }
              })}
            </Inview>
          </TopScrollTxt>
          <TopScrollTri>
            <TopScrollTriImg src="/img/tri.png" />
          </TopScrollTri>
        </TopScroll>
      </TopFvContent>
    )
  }
}


const TopFvContent = styled.div`
position: relative;
&:before{
  content: '';
  padding-top: 63%;
  display:block;
  @media (max-width: 768px) {
  padding-top: 215.5%;
  } 
}
`;
const TopFvIllust = styled.img`
width: 100%;
position: absolute;
z-index: 1;
height: 100%;
width: 100%;
left: 0;
top: 0;
object-fit:cover;

opacity: 0;
transition: opacity 0.8s;

`;
const ChangeSwitch = styled.img`
  position: absolute;
  width: 5.5vw;
  right: 8vw;
  top: 7vw;
  z-index: 2;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 16vw;
    right: 7vw;
    top: 15vw;
  }
`;

const TopScrollTxt = styled.div`
font-family: "Poiret One", cursive;
font-weight: 400;
font-size: 24px;
text-shadow: 0px 0px 5px #111;
letter-spacing: 5px;
-webkit-text-stroke: 0.5px #fff;
color: white;
overflow: hidden;
line-height: 1.2;
.w{
  display: inline-block;
  transform: translateY(-100%);
}
&.night{
}
&.day{

}
`;
const TopScrollTri = styled.div`
width: 25px;
margin-left: auto;
margin-right: auto;
margin-top: 5px;
`;

const triMove = keyframes`
  0% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0.755, 0.05,  0.855, 0.06 );
  }
  50%{
    transform: translateY(10px);
    animation-timing-function: cubic-bezier(0.23,1,0.32,1);
  }
  100%{
    transform: translateY(0);
  }
`

const TopScrollTriImg = styled.img`
animation: 3s ${triMove} infinite;
`;


TopFv.contextType = ThemeContext;

export default TopFv;