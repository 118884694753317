import styled from 'styled-components';
import React, { Component } from 'react';
import { ThemeContext, themes } from '../themeContext'
import TopHead from './TopHead'
import {
  Link
} from "react-router-dom";

class TopBnr extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const isDay = this.context.location.pathname === '/day' || this.context.location.pathname === '/day/' || this.context.location.pathname?.indexOf('/person-day') !== -1 || this.context.location.pathname?.indexOf('/yaitime-day') !== -1
    const isYaitime = this.context.location.pathname?.indexOf('yaitime') !== -1
    const bnrURLList = [
      { src: '/img/yaitime_banner4.jpg', to: "/person-day" }
      ,
      
      { src: '/img/yaitime_banner5.jpg', to: isDay ? '/yaitime-day' : '/yaitime-night' }
    ]
    return (
      <TopBnrWrap className={(isDay ? 'day' : "night") + (isYaitime ? ' yaitime' : "")}>
        <BnrItemList>
          {bnrURLList.map((data) => {
            return <BnrItem key={data.to}>
              <Link to={data.to}>
                <BnrImg src={data.src} />
              </Link>
            </BnrItem>;
          })}
        </BnrItemList>
      </TopBnrWrap>
    )
  }
}

const TopBnrWrap = styled.div`
text-align: center;
padding-top: 100px;
padding-bottom: 95px;
@media (max-width: 768px) {
  padding-top: 90px;
  }
&.night{
  background-color: #333333;
  &.yaitime{
    background-color:#3d3d3d;
  }
}
&.day{
  &.yaitime{
    background-color:#f1f1f1;
  }
}
`;
const BnrItemList = styled.ul`
  max-width: 1046px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  display: flex;
  justify-content: center;
  gap:70px;
  @media (max-width: 768px) {
    gap:10px;
    flex-direction: column;

  }
`;
const BnrItem = styled.li`
width: 27%;
  position: relative;
  &:before{
    content: '';
    display: block;
    padding-top: 100%;
  }
  @media (max-width: 768px) {
    margin-left: auto;
  margin-right: auto;
  width: 55%;
  &:not(:first-child){
    margin-top: 13px;
  }
  }
`;

const BnrImg = styled.img`
display: block;
position: absolute;
left: 0;top: 0;
width: 100%;
height: 100%;
object-fit: cover;

`;


TopBnr.contextType = ThemeContext;

export default TopBnr;