import { ThemeContext , themes} from './themeContext'

import React, { Component, useContext } from 'react';
import { Inview } from './Inview'

class ListItem extends Component {
  constructor(props) {
    super(props);
    this.test = (e)=>{
      const el = e.target
    }
  }
  
  componentDidMount() {
    const context = this.context;
    context.toggleDayState(true)
  }

  render() {
    const {
      title,
      description
    } = this.props;

    return (
      <Inview onInView={this.test}>
        <div className="istItem">
          <div className="istItem-title">{String(this.context.isDay)}</div>
          <div className="istItem-description">{description}</div>
        </div>
      </Inview>
    )
  }
}

ListItem.contextType = ThemeContext;
export default ListItem;