
import React, { Component, useContext } from 'react';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { ThemeContext, themes } from '../components/themeContext'
import YaitimeDescContent from '../components/Yaitime/YaitimeDescContent'
import TopBnr from '../components/Top/TopBnr'
import styled, { keyframes } from 'styled-components';
import {
  Link,
} from "react-router-dom";
import { random } from 'gsap/gsap-core';

const YaitimeDescInner = styled.div`
max-width: 1050px;
margin-left: auto;
margin-right: auto;
width: 85%;
`;




class Top extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    //this.props.history.push('/day')
    this.context.setRouteProps(this.props)
    this.context.setLocation(this.props.location)
    const isTop = this.props.location.pathname === '/'
    if (isTop) {
      const now = new Date()
      const hour = now.getHours()
      if (hour > 5 && hour < 18) {
        this.props.history.push('/day')
      } else {
        this.props.history.push('/night')
      }
    }
  }
  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.context.setRouteProps(this.props)
      this.context.setLocation(location)
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    const currentID = this.props.match.params.id
    let isDay = ''
    if (this.context.location.pathname) {
      isDay = this.context.location.pathname.indexOf('/yaitime-day') !== -1
    }
    return (
      <TransitionGroup>
        <CSSTransition key={this.props.location.key} classNames={"pageTransition"} timeout={700} >
          <PersonDesc className={'page pageTop ' + (isDay ? 'day' : "night")}>
            <ThemeContext.Consumer>
              {(value) => {
                const { yaitimeData } = value;
                if (yaitimeData.length) {
                  const currentIndex = yaitimeData.findIndex(el => el.id === currentID)
                  let nextIndex, prevIndex;
                  if (yaitimeData.length - 1 === currentIndex) {
                    nextIndex = 0
                  } else {
                    nextIndex = currentIndex + 1
                  }
                  if (0 === currentIndex) {
                    prevIndex = yaitimeData.length - 1
                  } else {
                    prevIndex = currentIndex - 1
                  }
                  return (
                    <>
                      <YaitimeDescInner>
                        <YaitimeDescContent yaitime={yaitimeData[currentIndex]} />
                      </YaitimeDescInner>
                      <TopBnr />
                    </>
                  );
                }

              }}
            </ThemeContext.Consumer>
          </PersonDesc>

        </CSSTransition>
      </TransitionGroup>
    )
  }
}
const PersonDesc = styled.div`
&.night{
  background-color: #333;
}
`;

Top.contextType = ThemeContext;

export default Top;