import styled, { keyframes } from 'styled-components';
import React, { Component } from 'react';
import { ThemeContext } from '../themeContext'
import { Inview } from '../Inview'
import { gsap, TweenMax, Expo, Back, Power4, TimelineMax, Power2 } from "gsap";
import TopHead from '../Top/TopHead'


class PersonTopInfo extends Component {
  constructor(props) {
    super(props);


    this.onInView = (e) => {
      const el = e.target
      TweenMax.to(
        el.querySelectorAll(":scope p"),
        0.8,
        {
          opacity: 1,
          delay: 0.2
        }
      )
    }

  }
  componentDidMount() {

  }

  componentWillUnmount() {

  }
  render() {
    let isDay = ''
    if (this.context.location.pathname) {
      isDay = this.context.location.pathname.indexOf('/person-day') !== -1
    }
    return (
      <PersonTopInfoDom className={(isDay ? 'day' : "night")}>
        <TopHead main="YAITAERS" sub="人物図鑑ヤイターズ" />
        <Inview onInView={this.onInView}>

        <p className="intro">
          矢板市を熱くする人物図鑑です。
        </p>
        </Inview>
      </PersonTopInfoDom>
    )
  }
}


const PersonTopInfoDom = styled.div`
background-color: #f1f1f1;
padding: 120px 0 80px;
@media (max-width: 768px) {
  padding: 80px 7.5% 60px;
  }
&.day{ 
  background-color: #fff;
  color: #666666;
}
&.night{
  color: #ccc;
  background-color: #333333;
}
.intro{
  font-size: 16px;
  letter-spacing: 3px;
  line-height: 2.5;
  margin-top: 60px;
  opacity: 0;
  @media (max-width: 768px) {
    margin-top: 50px;
  }
}
`;


PersonTopInfo.contextType = ThemeContext;

export default PersonTopInfo;