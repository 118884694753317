import styled, { keyframes } from 'styled-components';
import React, { Component } from 'react';
import { ThemeContext } from '../themeContext'
import { Inview } from '../Inview'
import { gsap, TweenMax, Expo, Back, Power4, TimelineMax, Power2 } from "gsap";
import {
  Link,
} from "react-router-dom";
class PersonOther extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.gotoPerson = (slug) => {
      let isDay = ''
      if (this.context.location.pathname) {
        isDay = this.context.location.pathname.indexOf('/person-day') !== -1
      }
      if (isDay) {
        this.context.route.history.push(`/person-day/${slug}`)
      } else {
        this.context.route.history.push(`/person-night/${slug}`)
      }
    }
    this.gotoList = (slug) => {
      let isDay = ''
      if (this.context.location.pathname) {
        isDay = this.context.location.pathname.indexOf('/person-day') !== -1
      }
      if (isDay) {
        this.context.route.history.push(`/person-day`)
      } else {
        this.context.route.history.push(`/person-night`)
      }
    }
  }

  componentWillUnmount() {

  }
  render() {
    let isDay = ''
    if (this.context.location.pathname) {
      isDay = this.context.location.pathname.indexOf('/person-day') !== -1
    }
    return (
      <PersonOtherWrap className={(isDay ? 'day' : "night")}>
        <PersonOtherContent>
          <PersonOtherList>
            <PersonOtherItem className={(isDay ? 'day' : "night")} onClick={() => this.gotoPerson(this.props.prevData.id)}>
              <PersonOtherPic>

                {
                  this.props.prevData.pic_day ?
                    (<img src={isDay ? this.props.prevData.pic_day.url : this.props.prevData.pic_night.url} />)
                    : ''
                }

              </PersonOtherPic>
              <PersonOtherName>
                {this.props.prevData.name_jp}さん
              </PersonOtherName>

            </PersonOtherItem>

            <PersonOtherItem onClick={this.gotoList} className={(isDay ? 'day' : "night")}>
              <PersonOtherIndex>
                <Link to={isDay ? '/person-day' : "/person-night"}>
                  人物図鑑<br className="pc_only" />一覧を見る
                </Link>
              </PersonOtherIndex>
            </PersonOtherItem>

            <PersonOtherItem className={(isDay ? 'day' : "night")} onClick={() => this.gotoPerson(this.props.nextData.id)}>

              <PersonOtherName>
                {this.props.nextData.name_jp}さん
              </PersonOtherName>
              <PersonOtherPic>
              {
                  this.props.nextData.pic_day ?
                    (<img src={isDay ? this.props.nextData.pic_day.url : this.props.nextData.pic_night.url} />)
                    : ''
                }
              </PersonOtherPic>


            </PersonOtherItem>
          </PersonOtherList>
        </PersonOtherContent>
      </PersonOtherWrap>
    )
  }
}


const PersonOtherWrap = styled.div`
background-color: #f1f1f1;
padding: 70px 0;
@media (max-width: 768px) {
  padding: 0;
  }
&.day{ 
  color: #666666;
}
&.night{
  color: #ccc;
  background-color: #3d3d3d;
}
`;


const PersonOtherContent = styled.div`
max-width: 1050px;
width: 90%;
margin-left: auto;
margin-right: auto;
@media (max-width: 768px) {
  width: 100%;
  }
`;
const PersonOtherList = styled.div`
display: flex;
background-color: white;
letter-spacing: 2px;
@media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;
const PersonOtherItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
&:nth-child(1){
  width: calc(50% - 110px);
  text-align: left;
  position: relative;
  @media (max-width: 768px) {
    order:1;
    width: 50%;
    background-color: #f1f1f1;
  }
  &.night{
  background-color: #333;
  @media (max-width: 768px) {
    background-color: #3d3d3d;
  }
}

  &:before{
    content: '';
    right: 10px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    position: absolute;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    width: 30px;
    height: 30px;
    transition: transform 0.3s;
    @media (max-width: 768px) {
      right: auto;
      left: 10px;
      width: 10px;
      height: 10px;
  }
  }

  &:hover{
    &:before{
      transform: translate(-10px, -50%) rotate(45deg);
    }
    img{
      transform: scale(1.1);
    }
  }
}
&:nth-child(2){
  width: 220px;
  background-color: #333333;
  color: white;
  line-height: 1.8;
  @media (max-width: 768px) {
    order:3;
    width: 100%;
    padding: 36px 0;
  }
  &.night{
    color: #000;
    background-color: #ccc;
  }
}
&:nth-child(3){
  width: calc(50% - 110px);
  text-align: right;
  position: relative;
  @media (max-width: 768px) {
    background-color: #f1f1f1;
    order:2;
    width: 50%;
  }
  &.night{
  background-color: #333;
  @media (max-width: 768px) {
    background-color: #3d3d3d;
  }
}
  &:before{
    content: '';
    left: 10px;
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
    position: absolute;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    width: 30px;
    height: 30px;
    transition: transform 0.3s;
    @media (max-width: 768px) {
      left: auto;
      right: 10px;
      width: 10px;
      height: 10px;
  }
  }
  &:after{
    @media (max-width: 768px) {
    content: '';
    left: 0;
    top: 0%;
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: #d6d6d6;
  }
  }
  &:hover{
    &:before{
      transform: translate(10px, -50%) rotate(-45deg);
    }
    img{
      transform: scale(1.1);
    }
  }
}
`;
const PersonOtherPic = styled.div`
width: 35%;
position: relative;
overflow: hidden;
@media (max-width: 768px) {
  display: none;
  }
&:before{
  content: '';
  padding-top: 100%;
  display: block;
}
img{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
}
`;
const PersonOtherName = styled.div`
width: 65%;
padding: 0 20px;
@media (max-width: 768px) {
  width: 100%;
  padding: 30px 20px;
  font-size: 15px;
  text-align:center;
  }
`;
const PersonOtherIndex = styled.div`

`;




PersonOther.contextType = ThemeContext;

export default PersonOther;