
import React, { Component, useContext } from 'react';
import Top from "./layout/Top";
import PersonTop from "./layout/PersonTop";
import YaitimeTop from "./layout/YaitimeTop";
import PersonDesc from './layout/PersonDesc';
import YaitimeDesc from './layout/YaitimeDesc';
import ListItem from "./components/ListItem";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Header from './components/Header'
import {
  Link,
  useLocation,
  Switch,
} from "react-router-dom";
import SwitchBtn from './components/SwitchBtn'
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { ThemeContext, themes } from './components/themeContext'
import ScrollToTop from './components/ScrollToTop';

let location
class PageRoute extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const { match } = this.props;
  }
  render() {
    return (
      <Router>
        <Header />
        <SwitchBtn />
        <ScrollToTop>
          <div>
            <Switch>
              <Route exact path='/yaitime-day' component={YaitimeTop} />
              <Route exact path='/yaitime-night' component={YaitimeTop} />
              <Route exact path='/yaitime' component={YaitimeTop} />

              <Route exact path='/yaitime-day/:id' component={YaitimeDesc} />
              <Route exact path='/yaitime-night/:id' component={YaitimeDesc} />

              <Route exact path='/person-day' component={PersonTop} />
              <Route exact path='/person-night' component={PersonTop} />
              <Route exact path='/person' component={PersonTop} />

              <Route exact path='/person-day/:id' component={PersonDesc} />
              <Route exact path='/person-night/:id' component={PersonDesc} />

              <Route path='/day' component={Top} />
              <Route path='/night' component={Top} />
              <Route exact path='/' component={Top} />
            </Switch>
          </div>
        </ScrollToTop>
      </Router>
    )
  }
}

PageRoute.contextType = ThemeContext;


export default PageRoute;