import styled from 'styled-components';
import React, { Component } from 'react';
import { ThemeContext, themes } from './themeContext'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { withRouter } from 'react-router-dom';

import {
  Link, Router
} from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };

    this.menuOpen = () => {
      this.setState(
        {
          menuOpen: true,
        }
      )
    }
    this.menuClose = () => {
      this.setState(
        {
          menuOpen: false,
        }
      )
    }
    this.menuToggle = () => {
      this.setState((prevState) => ({
        menuOpen: !prevState.menuOpen
      }));
    }
  }
  componentDidMount(prevProps) {
    this.props.history.listen((location, action) => {
      this.setState(
        {
          menuOpen: false,
        }
      )
    });
  }
  componentWillMount() {

  }
  render() {
    const isDay = this.context.location.pathname === '/day' || this.context.location.pathname === '/day/' || this.context.location.pathname?.indexOf('/person-day') !== -1 || this.context.location.pathname?.indexOf('/yaitime-day') !== -1
    const isYaitime = this.context.location.pathname?.indexOf('yaitime') !== -1 || this.context.location.pathname?.indexOf('person-day') !== -1
    return (
      <AppHeader className={(isYaitime ? 'yaitime ': '') + (isDay ? 'day' : "night")}>
        <SpMenu className={(this.state.menuOpen ? 'open ' : '') + (isDay ? 'day' : "night")}>
          <MenuList>
            <MenuItem className={isDay ? 'day' : "night"}>
              <Link to={isDay ? '/day' : "/night"}>
                <MenuItemText src={isDay ? "/img/nav_furusato_white.svg" : "/img/nav_furusato.svg"} />
              </Link>
            </MenuItem>
            <MenuItem className={isDay ? 'day' : "night"}>
              <Link to={isDay ? '/person-day' : "/person-night"}>
                <MenuItemText src={isDay ? "/img/nav_jinbutsu_white.svg" : "/img/nav_jinbutsu.svg"} />
              </Link>
            </MenuItem>
            <MenuItem className={isDay ? 'day' : "night"}>
              <Link to={isDay ? '/yaitime-day' : "/yaitime-night"}>
                <MenuItemText src={isDay ? "/img/nav_yaita_white.svg" : "/img/nav_yaita.svg"} />
              </Link>
            </MenuItem>
          </MenuList>
        </SpMenu>
        <HeaderInner>

          <Link to={isDay ? '/day' : "/night"}>
            <HeaderLogo className={(this.state.menuOpen ? 'open ' : '') + (isDay ? 'day' : "night")} src={isDay ? !isYaitime ? "/img/header_logo_white.svg" : "/img/header_logo_666.svg" : "/img/header_logo.svg"} />
          </Link>

          <HumbMenu className={'' + (this.state.menuOpen ? 'open ' : '') + (isDay ? 'day' : "night") + (' sp_only ') + (isYaitime && ' yaitime')} onClick={this.menuToggle}>
            <span></span>
            <span></span>
            <span></span>
          </HumbMenu>

          <MenuListWrap className="pc_only">
            <MenuList>
              <MenuItem className={(isDay ? 'day' : "night") + (isYaitime ? ' yaitime' : '')}>
                <Link to={isDay ? '/day' : "/night"}>
                  <MenuItemText src={isDay ? !isYaitime ? "/img/nav_furusato_white.svg" : "/img/nav_furusato_666.svg" : "/img/nav_furusato.svg"} />
                </Link>
              </MenuItem>
              <MenuItem className={(isDay ? 'day' : "night") + (isYaitime ? ' yaitime' : '')}>
                <Link to={isDay ? '/person-day' : "/person-night"}>
                  <MenuItemText src={isDay ? !isYaitime ? "/img/nav_jinbutsu_white.svg" : "/img/nav_jinbutsu_666.svg" : "/img/nav_jinbutsu.svg"} />
                </Link>
              </MenuItem>
              <MenuItem className={(isDay ? 'day' : "night") + (isYaitime ? ' yaitime' : '')}>
                <Link to={isDay ? '/yaitime-day' : "/yaitime-night"}>
                  <MenuItemText src={isDay ? !isYaitime ? "/img/nav_yaita_white.svg" : "/img/nav_yaita_666.svg" : "/img/nav_yaita.svg"} />
                </Link>
              </MenuItem>
            </MenuList>
          </MenuListWrap>


        </HeaderInner>
      </AppHeader>
    )
  }
}

const AppHeader = styled.header`
position: fixed;
z-index: 5;
left: 0;
top: 0;
width: 100%;
padding: 0 2.4%;
padding-top: 24px;

&.yaitime{
  background-color: #fff;
  color: #666666;
  padding-bottom: 24px;
  border-bottom: 2px solid #f1f1f1;
  @media (max-width: 768px) {
    padding-top: 16px;
    padding-bottom: 16px;
    }
  &.night{
  border-bottom: 2px solid #3d3d3d;
  background-color: #333333;
}
}
@media (max-width: 768px) {
  padding: 0 5%;
  padding-top: 20px;
  }
`;

const SpMenu = styled.div`
position: fixed;
z-index: 3;
left: 0;
top: 0;
width: 100%;
height: 100%;
pointer-events: none;
opacity: 0;
transition: opacity 0.4s;
padding-top: 60px;
&.night{
  background-color: rgba(26, 40, 39, 0.75);
}
&.day{
  background-color: rgba(25, 131, 137, 0.75);
}
&.open{ 
  pointer-events: all;
  opacity: 1;
}
`;


const HumbMenu = styled.div`
width: 20px;
height: 20px;
position: relative;
&.coming{
display: none;
}
&.day{ 
  span{
    background-color: #fff;
  }
  &.yaitime{
    span{
      background-color: #666666;
    }
  }
}
&.night{
  span{
    background-color: #ccc;
  }
}
span{
  position: absolute;
  left: 0;
  top: 0;
  height: 1px;
  width: 100%;
  &:nth-child(1){
    transform: translateY(0px);
  }
  &:nth-child(2){
    transform: translateY(8px);
  }
  &:nth-child(3){
    transform: translateY(16px);
  }
}
&.open{
  span{
    &:nth-child(1){
      transform: translateY(8px) rotate(45deg);
    }
    &:nth-child(2){
      opacity: 0;
    }
    &:nth-child(3){
      transform: translateY(8px) rotate(-45deg);
    }
  }
}
`;

const HeaderInner = styled.div`
display: flex;
justify-content: space-between; 
align-items: center;
position: relative;
z-index: 3;
`;

const MenuListWrap = styled.div`
&.coming{
 display: none;
}
`;
const HeaderLogo = styled.img`
width: 314px;
display: block;
@media (max-width: 1056px) {
  width: 27vw;
  }
  @media (max-width: 768px) {
    width: 50vw;
  }
  &.open{
    opacity: 0.3;
  }
`;
const MenuList = styled.ul`
display: flex;
@media (max-width: 768px) {
  display: block;
  }
`;
const MenuItem = styled.li`
padding: 0 15px;
position: relative;
@media (max-width: 768px) { 
  padding: 17px 0;
  border-bottom: 1px solid white;
  }
&::before, &:after{
  content: '';
  position: absolute;
  left: 0;
  top: calc(50% - 8px);
  height: 16px;
  width: 1px;
  @media (max-width: 768px) {
    content: none;
  }
}
&.day{ 
  @media (max-width: 768px) {
    border-color: #fff;
  }
  &::before, &:after{
    background-color: #fff;
  }
  &.yaitime{
    @media (max-width: 768px) {
    border-color: #666;
  }
  &::before, &:after{
    background-color: #666;
  }
  }
}

&.night{ 
  @media (max-width: 768px) {
    border-color: #cccccc;
  }
  &::before, &:after{
    background-color: #cccccc;
  }
}

&:after{
  left: auto;
  right: 0;
}
&:not(:last-child) {
  &:after{
    content: none;
  }
}
`;
const MenuItemText = styled.img`
height: 14.5px;
@media (max-width: 1056px) {
  height: 1.3vw;
  }
  @media (max-width: 768px) {
    height: 16px;
  }
`;


Header.contextType = ThemeContext;

export default withRouter(Header);