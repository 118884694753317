
import React, { Component, useContext } from 'react';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { ThemeContext, themes } from '../components/themeContext'

import YaitimeFv from '../components/Yaitime/YaitimeFv'
import PersonTopInfo from '../components/Person/PersonTopInfo'
import YaitimeList from '../components/Yaitime/YaitimeList'
import styled, { keyframes } from 'styled-components';
import TopBnr from '../components/Top/TopBnr';

class PersonTop extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.context.setRouteProps(this.props)
    this.context.setLocation(this.props.location)
    this.checkData(this.props.location)
    this.unlisten = this.props.history.listen((location, action) => {
      this.context.setRouteProps(this.props)
      this.context.setLocation(location)
      this.checkData(location)
    });
  }

  checkData(location) {
    const isTop = location.pathname === '/yaitime' || location.pathname === '/yaitime/'
    if (isTop) {
      const now = new Date()
      const hour = now.getHours()
      if (hour > 5 && hour < 18) {
        this.props.history.push('/yaitime-day')
        const lo = { pathname: '/yaitime-day' }

        this.context.setLocation(lo)
      } else {
        this.props.history.push('/yaitime-night')
        const lo = { pathname: '/yaitime-night' }
        this.context.setLocation(lo)
      }
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    const isDay = this.context.location.pathname === '/day' || this.context.location.pathname === '/day/' || this.context.location.pathname?.indexOf('/person-day') !== -1 || this.context.location.pathname?.indexOf('/yaitime-day') !== -1
    let fvImage
    if (isDay) {
      fvImage = '/img/person_top_day_sample.jpg'
    } else {
      fvImage = '/img/person_top_night_sample.jpg'
    }
    return (
      <TransitionGroup>
        <CSSTransition key={this.props.location.key} classNames={"pageTransition"} timeout={700} >
          <div className={(isDay ? 'day' : "night")}>
            <ThemeContext.Consumer>
              {(value) => {
                const { yaitimeData } = value
                return (
                  <>
                    <PersonTopDom>
                      <YaitimeFv />
                      <YaitimeList yaitimeData={yaitimeData} />
                      <TopBnr />
                    </PersonTopDom>
                  </>
                );
              }}
            </ThemeContext.Consumer>
          </div>
        </CSSTransition>
      </TransitionGroup>
    )
  }
}

const PersonTopDom = styled.div`

`;

PersonTop.contextType = ThemeContext;

export default PersonTop;