import styled, { keyframes } from 'styled-components';
import React, { Component } from 'react';
import { ThemeContext, themes } from '../themeContext'
import TopHead from './TopHead'
import { Inview } from '../Inview'
import { gsap, TweenMax, Expo, Back, Power4, TimelineMax, Power2 } from "gsap";


class TopContents extends Component {
  constructor(props) {
    super(props);

    this.picInview = (e) => {
      const el = e.target
 
      TweenMax.to(
        el.querySelector(":scope .picWrap img"),
        0.8,
        {
          ease: Power2.easeInOut,
          opacity: 1,
          delay: 0.2
        }
      )

      TweenMax.to(
        el.querySelector(":scope .contentsDesc"),
        1,
        {
          opacity: 1,
          delay: 0.5
        }
      )
      TweenMax.to(
        el.querySelector(":scope .reserve-txt"),
        1,
        {
          y: 0,
          ease: Power4.easeOut,
          delay: 0.8
        }
      )
    }

    this.state = {
      officeCalender: false,
      kitchenCalender: false,
    }
    this.toggleOfficeCalenderModal = () => {
      this.setState((prevState) => ({
        officeCalender: !prevState.officeCalender
      }));
    }
    this.toggleKitchenCalenderModal = () => {
      this.setState((prevState) => ({
        kitchenCalender: !prevState.kitchenCalender
      }));
    }



  }
  componentDidMount() { //26 //29
    const { match } = this.props;
  }
  render() {
    const isDay = this.context.location.pathname === '/day' || this.context.location.pathname === '/day/' || this.context.location.pathname?.indexOf('/person-day') !== -1 || this.context.location.pathname?.indexOf('/yaitime-day') !== -1
    return (
      <TopContentsWrap className={isDay ? 'day' : "night"}>

        <CalenderModal className={(this.state.officeCalender && 'open ') + (isDay ? 'day' : "night")}>
          <CalenderClose className={(isDay ? 'day' : "night")} onClick={this.toggleOfficeCalenderModal}>
          </CalenderClose>
          <CalenderHead className={(isDay ? 'day' : "night")}>
            <TopHead main="CALENDER" sub="シェアオフィス予約状況" />
          </CalenderHead>
          <CalenderArea className={isDay ? 'day' : "night"}>
            <CalenderWrap>
              <iframe className="calenderIframe" src="https://calendar.google.com/calendar/embed?src=q167ir900fgor82qhh1df5aqp8%40group.calendar.google.com&ctz=Asia%2FTokyo" scrolling="no"></iframe>
            </CalenderWrap>
          </CalenderArea>
        </CalenderModal>

        <CalenderModal className={(this.state.kitchenCalender && 'open ') + (isDay ? 'day' : "night")}>
          <CalenderClose className={(isDay ? 'day' : "night")} onClick={this.toggleKitchenCalenderModal}>
          </CalenderClose>
          <CalenderHead className={(isDay ? 'day' : "night")}>
            <TopHead main="CALENDER" sub="シェアキッチン予約状況" />
          </CalenderHead>
          <CalenderArea className={isDay ? 'day' : "night"}>
            <CalenderWrap>
              <iframe className="calenderIframe" src="https://calendar.google.com/calendar/embed?src=a98hiqlv5f1c413io9r4c2r9t0%40group.calendar.google.com&ctz=Asia%2FTokyo" scrolling="no"></iframe>
            </CalenderWrap>
          </CalenderArea>
        </CalenderModal>

        <TopContentsList>
          <TopContentsItem>
            <TopHead main="SHARE OFFICE" sub="シェアオフィス" />
            <MainContents className={isDay ? 'day' : "night"}>
              <Inview onInView={this.picInview}>
                <MainContentsInner>
                  <ContentsPic className="picWrap">
                    <ContentsPicImg src="/img/takibi_shareoffice_2.jpg" />
                  </ContentsPic>
                  <ContentsInfo>
                    <ContentsDesc className="contentsDesc">
                    無料で学習やテレワークスペース、交流スペースとして利用可能！<br/>モニターの貸出やWi-Fiも無料でお使いいただけます。
                </ContentsDesc>
                   
                  </ContentsInfo>
                </MainContentsInner>
              </Inview>
            </MainContents>
          </TopContentsItem>


          <TopContentsItem>
            <TopHead main="SHARE KITCHEN" sub="シェアキッチン" />
            <MainContents className={isDay ? 'day' : "night"}>
              <Inview onInView={this.picInview}>
                <MainContentsInner>
                  <ContentsPic className="picWrap">
                    <ContentsPicImg src="/img/takibi_sharekitche_2.jpg" />
                  </ContentsPic>
                  <ContentsInfo>
                    <ContentsDesc className="contentsDesc">
                    創業・起業支援を目的としたシェアキッチンです。
<br/>飲食店の営業や各種催し物にご利用いただけます。
<br/>備え付けの調理器具・食器・冷蔵庫・冷凍庫等をご用意しております。
<br/>詳細やご予約は下記までお問い合わせください。
                </ContentsDesc>
                    <ContentsLinkWrap>

                      <ContentsLink onClick={this.toggleKitchenCalenderModal}>
                        <span className={isDay ? 'day line' : "night line"}></span>
                        <span className="reserve-txt">
                          予約状況を見る
                    </span>
                      </ContentsLink>
                    </ContentsLinkWrap>
                  </ContentsInfo>
                </MainContentsInner>
              </Inview>
            </MainContents>
          </TopContentsItem>
        </TopContentsList>


      </TopContentsWrap>
    )
  }
}

const CalenderClose = styled.div`
position: absolute;
right: 30px;
top: 30px;
width: 50px;
height: 50px;
z-index: 5;
cursor: pointer;
@media (max-width: 768px) {
  top: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  }
&::before,&:after{
  content: '';
  width: 100%;
  background-color: #666666;
  height: 1px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: rotate(45deg);
}
&:after{
  transform: rotate(-45deg);
}

&.night {
&::before,&:after{
  background-color: #ccc;
}
}
&.day {
&::before,&:after{
  background-color: #666666;
}
}

`;
const CalenderModal = styled.div`
position: fixed;
left: 0;
top: 0;
width: 100%;
height: 100%;
z-index: 100;
background-color: #fff;
color: #666666;
min-height: 100vh;
background-color: #f1f1f1;
overflow-y: scroll;
opacity: 0;
pointer-events: none;
transition: opacity 0.3s;
&.open{
pointer-events: all;
  opacity: 1;
}

&.night{
  background-color: #fff;
}
&.day{
  background-color: #fff;
}


`;
const CalenderHead = styled.div`
padding-top: 70px;
padding-bottom: 70px;
@media (max-width: 768px) {
  padding-top: 45px;
  padding-bottom: 45px;
  }
&.night{
  background-color: #333333;
}
&.day{
  background-color: #fff;
  border-bottom: 2px solid #ddd;
  @media (max-width: 768px) {
    border-bottom: 1px solid #ddd;
  }
}

`;
const CalenderArea = styled.div`
padding-top: 70px;
padding-bottom: 70px;
@media (max-width: 768px) {
  padding-top: 45px;
  padding-bottom: 45px;
  }
`;
const CalenderWrap = styled.div`
margin-left: auto;
margin-right: auto;
max-width: 600px;
width: 90%;
position: relative;
background-color: #EAEAEA;
@media (max-width: 768px) {
  width: 120%;
margin-left: -10%;
transform: scale(0.75);
transform-origin: center top;
  }
&:before{
display: block;
content: '';
padding-top: 100%;
display: block;
content: "";
mix-blend-mode: hue;
z-index: 4;
position: relative;
background-color: #fff;
pointer-events: none;
@media (max-width: 768px) {
  padding-top: 100%;
  }
}
iframe{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  border: 0;
  height: calc(100% - 7px);
}
`;

const TopContentsWrap = styled.div`
&.night{
  background-color: #333333;
}
&.night{ 

}
`;

const TopContentsList = styled.div`
`;


const TopContentsItem = styled.div`
padding-top: 30px;
&:not(:first-child){
  margin-top: 60px;
}
`;
const MainContents = styled.div`
margin-top: 80px;
&.night{
  background-color: #3d3d3d;
  color: #cccccc;
}
&.day{
  background-color: #f1f1f1;
  color: #333333;
}
`;
const MainContentsInner = styled.div`
  max-width: 1046px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    display: block;
    width: 85%;
    padding-bottom: 45px;
  }
`;
const ContentsPic = styled.div`
position: relative;
width: 38%;
//transform: translateX(-100%);
overflow: hidden;
@media (max-width: 768px) {
  margin-left: auto;
  margin-right: auto;
  width: 65%;
  }
&:before{
  content: '';
  padding-top: 100%;
  display: block;
}
`;
const ContentsPicImg = styled.img`
position: absolute;
left:0;
top: 0;
width: 100%;
height: 100%;
object-fit: cover;
//transform: translateX(100%);
opacity: 0;
`;
const ContentsInfo = styled.div`
width: 58%;
text-align: left;
position: relative;
padding-top: 36px;
@media (max-width: 768px) {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  }
`;
const ContentsDesc = styled.p`
font-size: 16px;
letter-spacing: 4px;
line-height: 2.5;
text-align: justify;
text-justify: inter-ideograph;
opacity: 0;
@media (max-width: 1056px) {
  font-size: 1.4vw;
  }
@media (max-width: 768px) {
  font-size: 4.2vw;
  letter-spacing: 2px;
  
  }

`;

const triMove = keyframes`
  0% {
    transform: translateX(-100%);
    animation-timing-function: cubic-bezier(0.23,1,0.32,1);
  }
  40%{
    transform: translateX(0%);
  }
  60%{ 
    transform: translateX(0%);
    animation-timing-function: cubic-bezier(0.23,1,0.32,1);
  }
  100%{
    transform: translateX(100%);
  }
`

const ContentsComing = styled.span`
position: absolute;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
width: 110%;
background-color: rgba(0,0,0,0.45);
color: white;
z-index: 3;
font-weight: 500;
text-align:center;
padding: 5px 0;
`;

const ContentsLinkWrap = styled.span`
 position: absolute;
left: 0;
bottom: 30px;
@media (max-width: 768px) {
  justify-content: center;
  position: relative;
  bottom: 0; 
  margin-top: 35px;
  display: inline-block;
  }
`;
const ContentsLink = styled.span`
display: flex;
align-items: center;
letter-spacing: 3px;
font-weight: normal;
overflow: hidden;
position: relative;
z-index: 1;
cursor: pointer;
@media (max-width: 768px) {
  justify-content: center;
  }
.reserve-txt{
  display: inline-block;
  transform: translateY(100%);
}
.line{
  width: 60px;
  display: inline-block;
  height: 1px;
  margin-right: 10px;
  position: relative;
  overflow: hidden;
  @media (max-width: 768px) {
    width: 40px;
  }
  &:before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    animation: 3s ${triMove} infinite;
  }
  &.night{
    &:before{
      background-color: #cccccc;
  }
}
&.day{
  &:before{
    background-color: #333333; 
  }
}
}


`;

TopContents.contextType = ThemeContext;

export default TopContents;