import styled, { keyframes } from 'styled-components';
import React, { Component } from 'react';
import { ThemeContext } from '../themeContext'


class YaitimeFv extends Component {
  constructor(props) {
    super(props);

  }
  render() {
    const isDay = this.context.location.pathname === '/day' || this.context.location.pathname === '/day/' || this.context.location.pathname?.indexOf('/person-day') !== -1 || this.context.location.pathname?.indexOf('/yaitime-day') !== -1
    return (
      <YaitimeFvContent>

<div className="inner">
        <img src="/img/yaitime/fv.jpg" />
</div>
      </YaitimeFvContent>
    )
  }
}


const YaitimeFvContent = styled.div`
position: relative; 
padding-top: 78px;
@media (max-width: 768px) {
  padding-top: 54px;
  }
  .inner{
    position: relative;
    &:before{
      content:'';
      display: block;
      @media (max-width: 768px) {
        padding-top: 100%;
      }
    }
}
img{
  display: block;
  @media (max-width: 768px) {
    position: absolute;
    left: 0;
    top: -10%;
    width: 100%;
    height: 110%;
    object-fit: cover;
    object-position: center top;
  }
}
`;

YaitimeFv.contextType = ThemeContext;

export default YaitimeFv;