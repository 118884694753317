import styled, { keyframes } from 'styled-components';
import React, { Component } from 'react';
import { ThemeContext } from '../themeContext'
import { Inview } from '../Inview'
import { gsap, TweenMax, Expo, Back, Power4, TimelineMax, Power2 } from "gsap";


class PersonDescMain extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
  }

  componentWillUnmount() {

  }
  render() {
    let isDay = ''
    if (this.context.location.pathname) {
      isDay = this.context.location.pathname.indexOf('/person-day') !== -1
    }
    return (
      <PersonDescMainWrap className={(isDay ? 'day' : "night")}>
        <PersonInfo className={(isDay ? 'day' : "night")}>
          <PersonProfile dangerouslySetInnerHTML={{ __html: this.props.person.profile }}>
          </PersonProfile>
          <PersonInfoList>
            <PersonInfoItem>
              <PersonInfoName>
                # 活動拠点
              </PersonInfoName>
              <PersonInfoContent dangerouslySetInnerHTML={{ __html: this.props.person.place }}>
              </PersonInfoContent>
            </PersonInfoItem>
            <PersonInfoItem>
              <PersonInfoName>
                # こんな活動しています
              </PersonInfoName>
              <PersonInfoContent dangerouslySetInnerHTML={{ __html: this.props.person.what }}>
              </PersonInfoContent>
            </PersonInfoItem>
            <PersonInfoItem>
              <PersonInfoName>
                # こんなお手伝いできます
              </PersonInfoName>
              <PersonInfoContent dangerouslySetInnerHTML={{ __html: this.props.person.help }}>
              </PersonInfoContent>
            </PersonInfoItem>
            <PersonInfoItem>
              <PersonInfoName>
                # こんな人や活動と繋がりたい
              </PersonInfoName>
              <PersonInfoContent dangerouslySetInnerHTML={{ __html: this.props.person.communicate }}>
              </PersonInfoContent>
            </PersonInfoItem>
            <PersonInfoItem>
              <PersonInfoName>
                # 関連サイト・SNS
              </PersonInfoName><br className="sp_only" />
              <PersonInfoContent>
                <a href={this.props.person.site} target="_blank">
                  {this.props.person.site}
                </a>
              </PersonInfoContent>
            </PersonInfoItem>
          </PersonInfoList>
          <PersonBtnWrap>
            <PersonBtn href={this.props.person.contactLink} target="_blank" className={(isDay ? 'day' : "night")}>
              この<span className={(isDay ? 'day' : "night") + ' en'}>YAITAERS</span>とツナガリタイ
            </PersonBtn>
          </PersonBtnWrap>
        </PersonInfo>
        <PersonContent>
          <PersonContentInner className="rich-text-content" dangerouslySetInnerHTML={{ __html: isDay ? this.props.person.content : this.props.person.content_night }}>
          </PersonContentInner>
        </PersonContent>

      </PersonDescMainWrap>
    )
  }
}


const PersonDescMainWrap = styled.div`

text-align: left; 
&.day{ 
  color: #666666;
}
&.night{
  color: #ccc;
}
`;
const PersonInfo = styled.div`
padding-top: 40px;
padding-bottom: 40px;
border-bottom: 2px solid #e8e8e8;
border-top: 2px solid #e8e8e8;
@media (max-width: 768px) {
  padding-top: 55px;
  padding-bottom: 55px;
  }
&.day{ 

}
&.night{
  border-color: #444444;
}
`;
const PersonProfile = styled.div`
letter-spacing: 4px;
line-height: 2.5;
`;
const PersonInfoList = styled.div`
margin-top: 40px;
`;

const PersonInfoItem = styled.div`
  letter-spacing: 4px;
  line-height: 2.5;
  &:not(:first-child){
    @media (max-width: 768px) {
      margin-top: 30px;
  }
  }
`;
const PersonInfoName = styled.span`
font-weight: 700;
margin-right: 18px;
`;
const PersonInfoContent = styled.span`
a{
  color: #339999;
}
`;
const PersonBtnWrap = styled.div`
margin-top: 40px;
@media (max-width: 768px) {
  margin-top: 50px;
  }
`;
const PersonBtn = styled.a`
padding: 8px 50px;
border: 2px solid #e8e8e8;
display: inline-block;
letter-spacing: 3px;
transition: all 0.3s;
@media (max-width: 768px) {
  padding: 2vw 0vw;
  width: 100%;
  text-align: center;
  font-size: 4vw;
  }
&:hover{
  border-color: #ccc;
  background-color: #cccccc;
  color: white;
  .en{
    &.day{
      -webkit-text-stroke: 0.2px #fff;
    }
  }
}
&.night{
  border-color:#444;
  &:hover{
    border-color: #666;
    background-color: #666;
    color: #333;
    .en{
      -webkit-text-stroke: 0.2px #333;
    }
  }
}
.en{
  font-family: "Poiret One", cursive;
  letter-spacing: 4px;  
  &.day{ 
    -webkit-text-stroke: 0.2px #666666;
  }
  &.night{
    -webkit-text-stroke: 0.2px #ccc;
  }
}
`;

const PersonContent = styled.div`
padding-top: 60px;
padding-bottom: 60px;
`;
const PersonContentInner = styled.div`

`;


PersonDescMain.contextType = ThemeContext;

export default PersonDescMain;