import React, { Component } from 'react'
import './scss/App.scss';
import ListItem from './components/ListItem'
import styled from 'styled-components';
import dotenv from 'dotenv'
import {
  Link,
  useLocation,
  Switch,
  withRouter
} from "react-router-dom";
import Top from './layout/Top'
import { ThemeContext, initialContext } from './components/themeContext'
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Header from './components/Header'
import AppFooter from './components/AppFooter'
import SwitchBtn from './components/SwitchBtn'
import PageRoute from './PageRoute'
dotenv.config()



class App extends Component {

  // データをテンプレートに受け渡す部分の処理を記述します

  constructor(props) {
    super(props);

    this.toggleDayState = (isDay) => {
      this.setState({
        isDay: isDay
      });
    };
    this.setRouteProps = (route) => {
      this.setState({
        route: route
      });
    };
    this.setLocation = (location) => {
      this.setState({
        location: location
      });
    };

    // state には更新する関数も含まれているため、
    // コンテクストプロバイダにも渡されます。
    this.state = {
      data: '',
      personData: [],
      yaitimeData: [],
      name: "Nakanishi",
      toggleDayState: this.toggleDayState,
      setRouteProps: this.setRouteProps,
      setLocation: this.setLocation,
      isDay: initialContext.isDay,
      route: '',
      location: '',
    }
  }

  getStaticProps = async () => {
    const key = {
      headers: { 'X-API-KEY': process.env.REACT_APP_API_KEY },
    };
    const data = await fetch('https://takibi.microcms.io/api/v1/person', key)
      .then(res => {
        return res.json()
      })
      .catch((e) => {
      });
    this.setState({
      personData: data.contents,
      //personData
    })
  };
  getYaitimeData = async () => {
    const key = {
      headers: { 'X-API-KEY': process.env.REACT_APP_API_KEY },
    };
    const data = await fetch('https://takibi.microcms.io/api/v1/yaitime', key)
      .then(res => {
        return res.json()
      })
      .catch((e) => {
      });
    this.setState({
      yaitimeData: data.contents,
      //personData
    })
  };

  componentDidMount() {
    this.getStaticProps()
    this.getYaitimeData()
  }

  changeState = () => this.setState({ name: "change" });

  render() {
    return (
      <ReactApp className="App" id="takibi-app">
        <ThemeContext.Provider value={this.state}>
          <PageRoute />
          <AppFooter />
        </ThemeContext.Provider>
      </ReactApp>
    )
  }
}


const ReactApp = styled.div`
    text-align: center;
`;


export default App;
