import styled from 'styled-components';
import React, { Component, useContext } from 'react';
import { ThemeContext, themes } from './themeContext'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { withRouter } from 'react-router-dom';

class SwitchBtn extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.onClick = () => {
      const isDay = this.context.location.pathname === '/day' || this.context.location.pathname === '/day/' || this.context.location.pathname?.indexOf('/person-day') !== -1 || this.context.location.pathname?.indexOf('/yaitime-day') !== -1
      const pathname = this.context.location.pathname
      const noSlashPath = pathname[pathname.length - 1] === '/' ? pathname.slice(0, pathname.length - 1) : pathname;
      const isTopPage = noSlashPath === '/day' || noSlashPath === '/night'
      if (isTopPage) {
        if (isDay) {
          this.context.route.history.push('/night')
        } else {
          this.context.route.history.push('/day')
        }
      } else {
        if (noSlashPath.indexOf('person') !== -1) {
          if (isDay) {
            this.context.route.history.push(this.context.location.pathname.replace('person-day', 'person-night'))
          } else {
            this.context.route.history.push(this.context.location.pathname.replace('person-night', 'person-day'))
          }
        } else if (noSlashPath.indexOf('yaitime') !== -1){
          if (isDay) {
            this.context.route.history.push(this.context.location.pathname.replace('yaitime-day', 'yaitime-night'))
          } else {
            this.context.route.history.push(this.context.location.pathname.replace('yaitime-night', 'yaitime-day'))
          }
        }
      }
    }
  }
  render() {
    const isDay = this.context.location.pathname === '/day' || this.context.location.pathname === '/day/' || this.context.location.pathname?.indexOf('/person-day') !== -1 || this.context.location.pathname?.indexOf('/yaitime-day') !== -1
    return (
      <SwitchBtnWrap>
        <ArrowTop>
          <AnchorLink href='#takibi-app'>
            <ArrowTopImg src={isDay ? "/img/arrow_top_day.png" : "/img/arrow_top_night.png"} />
          </AnchorLink>
        </ArrowTop>
        {/* <ChangeIcon onClick={this.onClick}>
          <ChangeIconImg src={isDay ? "/img/change_icon_day.png" : "/img/change_icon_night.png"} />
        </ChangeIcon> */}
      </SwitchBtnWrap>
    )
  }
}

const ArrowTop = styled.div`

`;

const ArrowTopImg = styled.img`
transition: transform 0.8s cubic-bezier(0.23,1,0.32,1);
&:hover{
    transform: translateY(-10px);
}
`;
const ChangeIcon = styled.div`
margin-top: 5px;
`;
const ChangeIconImg = styled.img`
transition: transform 0.8s cubic-bezier(0.23,1,0.32,1);
&:hover{
    transform: scale(1.1);
    }
`;


const SwitchBtnWrap = styled.div`
position: fixed;
right: 3%;
bottom: 40px;
z-index: 10;
width: 44px;
cursor: pointer;
`;


SwitchBtn.contextType = ThemeContext;

export default withRouter(SwitchBtn);