
import React, { Component, useContext } from 'react';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { ThemeContext, themes } from '../components/themeContext'
import TopFv from '../components/Top/TopFv'
import TopAbout from '../components/Top/TopAbout'
import TopContents from '../components/Top/TopContents'
import TopBnr from '../components/Top/TopBnr'

class Top extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.context.setRouteProps(this.props)
    this.context.setLocation(this.props.location)
    this.checkData(window.location)
    this.unlisten = this.props.history.listen((location, action) => {
      this.checkData(location)
    });
  }

  checkData(location){
    this.context.setRouteProps(this.props)
    this.context.setLocation(location)
    const isTop = this.props.location.pathname === '/'
    if (isTop) {
      const now = new Date()
      const hour = now.getHours()

      this.props.history.push('/day')
      const lo = { pathname: '/day' }
      this.context.setLocation(lo)
   /*    if (hour > 5 && hour < 18) {
      } else {
        this.props.history.push('/night')
        const lo = { pathname: '/night' }
        this.context.setLocation(lo)
      } */
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    return (
      <TransitionGroup>
        <CSSTransition key={this.props.location.key} classNames={"pageTransition"} timeout={700} >
          <div className="pageTop page">
            <TopFv />
            <TopAbout />
            <TopContents />
            <TopBnr />
          </div>
        </CSSTransition>
      </TransitionGroup>
    )
  }
}
Top.contextType = ThemeContext;

export default Top;