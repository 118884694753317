import styled from 'styled-components';
import React, { Component } from 'react';
import { ThemeContext, themes } from '../themeContext'
import TopHead from './TopHead'
import { gsap, TweenMax, Expo, Back, Power4, TimelineMax, Power2 } from "gsap";
import { Inview } from '../Inview'

class TopAbout extends Component {
  constructor(props) {
    super(props);

    this.logoInview = (e) => {
      const el = e.target
      TweenMax.to(
        el.querySelectorAll(":scope img"),
        1,
        {
          opacity: 1,
          delay: 0.3
        }
      )
    }



    this.aboutDescInview = (e) => {
      const el = e.target
      TweenMax.to(
        el.querySelectorAll(":scope .aboutDesc"),
        1,
        {
          opacity: 1,
          delay: 0.2
        }
      )
    }
  }

  render() {
    const isDay = this.context.location.pathname === '/day' || this.context.location.pathname === '/day/' || this.context.location.pathname?.indexOf('/person-day') !== -1 || this.context.location.pathname?.indexOf('/yaitime-day') !== -1
    return (
      <TopAboutWrap className={isDay ? 'day' : "night"}>
        <div className="pc_only">
          <TopHead main="TAKIBI" sub="ふるさと支援センター タキビ" />
        </div>
        <div className="sp_only">
          <TopHead main="TAKIBI" sub="ふるさと支援センター<br>タキビ" />
        </div>
        <AboutLogo>
          <Inview onInView={this.logoInview}>
            <AboutLogoImg src={isDay ? '/img/takibi_logo_day.png' : "/img/takibi_logo_night.png"} />
          </Inview>
        </AboutLogo>
        <Inview onInView={this.aboutDescInview}>
          <AboutDesc className={isDay ? 'day aboutDesc pc_only' : "night aboutDesc pc_only"}>
            ふるさと支援センターTAKIBIは、<br />地域のみなさんや矢板に来たい方たちが、<br />焚き火を囲むようにいっしょにあったかい関係になることを目標に活動してます。<br />世代を超えて地域に集い、語りあい、地域の未来を熱くできる場所になれるよう今日も火を灯してます。
        </AboutDesc>
          <AboutDesc className={isDay ? 'day aboutDesc sp_only' : "night aboutDesc sp_only"}>
            ふるさと支援センターTAKIBIは、<br />地域のみなさんや矢板に来たい方たちが、<br />焚き火を囲むようにいっしょに<br />あったかい関係になることを目標に<br />活動してます。<br />世代を超えて地域に集い、語りあい、<br />地域の未来を熱くできる場所になれるよう今日も火を灯してます。
        </AboutDesc>
        </Inview>
      </TopAboutWrap>
    )
  }
}

const TopAboutWrap = styled.div`
text-align: center;
padding-top: 80px;
padding-bottom: 60px;
padding-left: 5%;
padding-right: 5%;
margin-left: auto;
margin-right: auto;
@media (max-width: 768px) {
  padding-top: 22vw;
  padding-bottom: 12vw;
  }
&.night{
  background-color: #333333;
}
&.day{

}
`;

const HeadMain = styled.h2`
font-size: 40px;
color: #666666;
&.night{

}
&.day{

}
`;

const AboutLogo = styled.div`
width: 235px;
margin-left: auto;
margin-right: auto;
margin-top: 80px;
@media (max-width: 768px) {
  width: 38vw;
  margin-top: 20vw;
  }
`;
const AboutLogoImg = styled.img`
opacity: 0;
`;
const AboutDesc = styled.p`
line-height: 2.5;
font-size: 16px;
letter-spacing: 3px;
margin-top: 60px;
opacity: 0;
@media (max-width: 768px) {
  font-size: 4.4vw;
  letter-spacing: 2px;
  }

&.day{
  color: #666666;
}
&.night{
  color: #cccccc;
}
`;

TopAbout.contextType = ThemeContext;

export default TopAbout;