import styled from 'styled-components';
import React, { Component } from 'react';
import { ThemeContext, themes } from '../themeContext'
import { Inview } from '../Inview'
import { gsap, TweenMax, Expo, Back, Power4, TimelineMax, Power2 } from "gsap";

class TopHead extends Component {
  constructor(props) {
    super(props);
    this.onInView = (e) => {
      const el = e.target
      TweenMax.staggerTo(
        el.querySelectorAll(":scope .w"),
        1.2,
        {
          y: "0px",
          force3D: true,
          ease: Power4.easeOut,
          delay: 0.3
        },
        0.03
      )
      TweenMax.to(
        el.querySelector(":scope .head-desc"),
        1.2,
        {
          opacity: 1,
          ease: Power4.easeOut,
          delay: 0.5
        }
      )

    }
  }
  componentDidMount() {
    const { match } = this.props;
  }
  render() {
    const isDay = this.context.location.pathname === '/day' || this.context.location.pathname === '/day/' || this.context.location.pathname?.indexOf('/person-day') !== -1 || this.context.location.pathname?.indexOf('/yaitime-day') !== -1
    return (
      <Inview onInView={this.onInView}>
        <TopHeadWrap className={(isDay ? 'day' : "night") + (this.props.black ? " black": '')}>
          <HeadMain>
            <HeadMainInner>
              {this.props.main.split('').map((w, i) => {
                if (w !== ' ') {
                  return <span key={i} className={`w w-${i}`}>{w}</span>;
                } else {
                  return <span key={i} className={`w w-${i}`}>&nbsp;</span>;
                }
              })}
            </HeadMainInner>
          </HeadMain>
          <HeadDesc className="head-desc" dangerouslySetInnerHTML={{ __html: this.props.sub }}>
          </HeadDesc>
        </TopHeadWrap>
      </Inview>
    )
  }
}

const TopHeadWrap = styled.div`
text-align: center;
&.day{
  color: #666666;
}
&.night{
  color: #ccc;
}
&.black{
  color: #666666;
}
`;

const HeadMain = styled.h2`
font-size: 40px;
font-family: "Poiret One", cursive;
font-weight: normal;
letter-spacing: 10px;
line-height: 1.5;
@media (max-width: 768px) {
  font-size: 9.6vw;
  letter-spacing: 6px;
  }
`;
const HeadMainInner = styled.div`
overflow: hidden;
line-height: 1.1;
.w{
  display: inline-block;
  transform: translateY(100%);
}
`;
// rsync -av ./memo.txt hogehoge@192.168.0.12:/home/hogehoge/memo.txt
// ssh r7932252@118.27.100.84 -p 8022  -i /Users/t.nagasawa/.ssh/nittokocha/id_rsa_nagasawa_birdman

// rsync -r  -e "ssh -i /Users/t.nagasawa/.ssh/nittokocha/id_rsa_nagasawa_birdman -p 8022" --exclude='.git' --exclude='.circleci' ./build/*  r7932252@118.27.100.84:~/public_html/dev.takibi.town/test/

const HeadDesc = styled.p`
font-size: 22px;
font-weight: 300;
font-family: "Noto Sans JP", sans-serif;
letter-spacing: 7px;
margin-top: 6px;
opacity: 0;
@media (max-width: 768px) {
  font-size: 4.2vw;
  letter-spacing: 3px;
  margin-top: 9px;
  }
`;

TopHead.contextType = ThemeContext;

export default TopHead;